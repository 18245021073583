@require '/src/dev'

/*


        Position helper utilities


*/

.u-text-right
    text-align: right

    +bp(max768)
        &--lt768
            text-align: right

    +bp(min768)
        &--gt768
            text-align: right

.u-text-left
    text-align: left

    +bp(max768)
        &--lt768
            text-align: left

    +bp(min768)
        &--gt768
            text-align: left

.u-text-center
    text-align: center

    +bp(max768)
        &--lt768
            text-align: center

    +bp(min768)
        &--gt768
            text-align: center

.u-valign-top
    vertical-align: top

.u-valign-middle
    vertical-align: middle

.u-valign-bottom
    vertical-align: bottom

.u-position-relative
    position: relative

.u-position-absolute
    position: absolute

.u-position-fixed
    position: fixed

.u-top
    top: 0

.u-bottom
    bottom: 0

.u-right
    right: 0

.u-left
    left: 0

.u-float-right
    float: right

    +bp(max768)
        &--lt768
            float: right !important

    +bp(min768)
        &--gt768
            float: right !important

.u-float-left
    float: left

    +bp(max768)
        &--lt768
            float: left !important

    +bp(min768)
        &--gt768
            float: left !important

