@require '/src/dev'

/*



    Usage: {prefix}{color-name}

    [prefix]
    u-          color
    u-bg-       background-color
    u-border-   border-color
    u-focus-    an elements outline-color on focus state

    {color-name}
    any color from the palette



*/

generate-colors()
    for c in $colorpalette
      .u-{c}
        color: lookup("$"+c)

    for c in $colorpalette
      .u-bg-{c}
        background-color: lookup("$"+c)

    for c in $colorpalette
      .u-border-{c}
        border-color: lookup("$"+c)

    for c in $colorpalette
      .u-focus-{c}
        outline-color: lookup("$"+c)


generate-colors()
