.u-
    &normal
        font-weight: normal

    &italics
        font-style: italic

    &strong
        font-weight: bold

    &strikethrough
        text-decoration: line-through

    &capitalize:first-letter
        text-transform: capitalize

    &uppercase
        text-transform: uppercase

    &t1,
    &t2,
    &t3,
    &t4,
    &t5
        font-weight: 300 // firefox translates normal to 400 other browsers 300

    &t1
        typo-size("t1")
        font-weight: bold

    &t2
        typo-size("t2")
        font-weight: normal

    &t3
        typo-size("t3")
        font-weight: normal

    &t4
        typo-size("t4")
        font-weight: bold

    &t5
        typo-size("t5")
        font-weight: bold

    &b1
        typo-size("base")
        font-weight: normal

    &d1
        typo-size("d1")
        font-weight: normal
