@require '/src/dev'

/*


        Spacing helper utilities


*/
/*



    Usage: {short for rule}{short for direction}{size}

    {short for rule}
    p - padding
    m - margin

    {short for direction}
    t - top
    r - right
    b - bottom
    l - left
    v - vertical
    h - horizontal
    a - all

    {size}
    [0, 2, 4, 8, 16, 32, 64, a (auto)]


*/


t(rule, size, u)
    {rule}-top (u ? unit(size, u) : size)
r(rule, size, u)
    {rule}-right (u ? unit(size, u) : size)
b(rule, size, u)
    {rule}-bottom (u ? unit(size, u) : size)
l(rule, size, u)
    {rule}-left (u ? unit(size, u) : size)
v(rule, size, u)
    t(rule, size, u)
    b(rule, size, u)
h(rule, size, u)
    r(rule, size, u)
    l(rule, size, u)
a(rule, size, u)
    {rule} (u ? unit(size, u) : size)

spacing()
    for n in a v h t r b l
        for m in $spacings
            .u-p{n}{m}
                n(padding, m, "px !important") 
            .u-m{n}{m}
                n(margin, m, "px !important")
        .u-m{n}a
            n(margin, auto, false)

spacing()
