@require '/src/dev'

/*


        Display helper utilities


*/
.u-display-none
    display: none

.u-display-block
    display: block

.u-display-inline
    display: inline

.u-display-inline-block
    display: inline-block

.u-display-table  // Rewrite to abstracts instead?
    displayTable()

.u-display-cell  // Rewrite to abstracts instead?
    displayCell()

.u-print-only
    printOnly()

.u-no-print
    noPrint()

.u-screen-reader-only
    screenReaderOnly()

.u-hide
    +bp(max480)
        &-lt480
            display: none !important

    +bp(min480)
        &-gt480
            display: none !important

    +bp(max640)
        &-lt640
            display: none !important

    +bp(min640)
        &-gt640
            display: none !important

    +bp(max768)
        &-lt768
            display: none !important

    +bp(min768)
        &-gt768
            display: none !important

    +bp(max900)
        &-lt900
            display: none !important

    +bp(min900)
        &-gt900
            display: none !important

    +bp(max1235)
        &-lt1235
            display: none !important

    +bp(min1235)
        &-gt1235
            display: none !important
