@require '/src/dev'

/*


        Clearfix helper utilities


*/

.u-
    &cb
        clear-both()

    &cf
        clear-fix()
